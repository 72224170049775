<template>
  <HomeView />
  <NavBar />
  <TheFooter />
  <CookieBanner />

  <!-- <section v-if="passwordProtected" class="password-protect">
    <form class="password-form p-4">

      <div class="form-group  ">
        <input v-model="enteredPassword" type="password" class="form-control" id="exampleInputPassword1"
          placeholder="Password">
      </div>

      <div @click="handleClick" type="submit" class="btn mt-2 btn-primary">Submit</div>
    </form>

  </section> -->
</template>
<script setup>
import "lazysizes";
import HomeView from "@/views/HomeView.vue";
import NavBar from "@/components/ui/NavBar.vue";
import TheFooter from "@/components/ui/TheFooter.vue";
import CookieBanner from "@/components/ui/CookieBanner.vue"
import { reactive, computed } from "vue";
import { useHead } from "@vueuse/head";

const siteData = reactive({
  title: "CFP Rotterdam",
  // description: "Creative Footprint Montréal",
});

useHead({
  title: computed(() => siteData.title),

});

// const enteredPassword = ref('')
// const passwordProtected = ref(false)

// function isPastSpecificTimeInCET() {
  // let currentDate = new Date();
  // let currentTime = new Date(currentDate.toLocaleString('en-US', { timeZone: 'Europe/Paris' }));

  // Define the launch time explicitly with the correct timezone (CET/CEST)
  // let launchTime = new Date('September 25, 2024 09:00:00 GMT+02:00');

  // Compare the two Date objects
//   if (currentTime > launchTime) {
//     passwordProtected.value = false
//   } else if (currentTime < launchTime) {
//     passwordProtected.value = true
//   } else {
//     passwordProtected.value = false
//   }
// }

// isPastSpecificTimeInCET()


  

  



// function handleClick() {
//   if (enteredPassword.value === '2024CreativeFootprint') {
//     passwordProtected.value = false
//     document.body.style.overflow = 'auto'
//   }
//   enteredPassword.value = ''
// }
</script>

<style lang="scss">
@font-face {
  font-family: "SkModernistRegular";
  src: url("~@/assets/fonts/sk-modernist-regular.woff2") format("woff2"),
    url("~@/assets/fonts/sk-modernist-regular.woff") format("woff");
}

@font-face {
  font-family: "SkModernistBold";
  src: url("~@/assets/fonts/sk-modernist-bold.woff2") format("woff2"),
    url("~@/assets/fonts/sk-modernist-bold.woff") format("woff");
}

@font-face {
  font-family: "SkModernistMono";
  src: url("~@/assets/fonts/sk-modernist-mono.woff2") format("woff2"),
    url("~@/assets/fonts/sk-modernist-mono.woff") format("woff");
}

#app {
  font-family: SkModernistRegular, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background: #201f1d;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.password-protect {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100000;
  background: #201f1d;
}

.password-form {
  width: 100%;
}

@media (min-width: 768px) {
  .password-form {
    width: 24rem;
  }
}

html,
body {
  background: #201f1d;
  font-size: 0.9rem;
  // overflow: hidden;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.overlay {
  position: fixed;
  z-index: 1000000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("@/assets/img/placeholder.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

html {
  height: -webkit-fill-available;
}

.section {
  position: relative;
  z-index: 2;
}

.title-underline {
  text-decoration: underline;
  text-decoration-thickness: 4px;
}

.swiper-wrapper {
  transition-timing-function: linear;
}

h3 {
  text-align: center;
  font-family: SkModernistBold;
  font-size: 2rem;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active {
  transition: all 0.3s ease-out;
}

.fade-leave-active {
  transition: all 0.3s ease-in;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.btn-outline-light {
  border-color: #e8fcd4 !important;
  color: #e8fcd4 !important;
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.active,
.open>.dropdown-toggle.btn-outline-light {
  background-color: #e8fcd4 !important;
  color: #201f1d !important;
}

.share-button {
  background-color: #e8fcd4 !important;
}

.share-button {
  background-color: #e8fcd4 !important;
  color: #201f1d !important;
}

.share-button__icon path {
  fill: #201f1d !important;
}
</style>
