<template>
  <div data-section="landing" id=""
    class="landing container-fluid d-flex justify-content-center align-items-center flex-column">
    <div data-aos="fade" data-aos-duration="2500" class="circle-bg"></div>
    <div class="circle-wrapper">
      <div :style="circleStyle(index)" v-for="index in 20" :key="index" class="circle-shape"></div>
    </div>

    <div class="container-fluid row col-12 content-wrapper p-0 m-0">
      <div data-aos="fade-right" data-aos-duration="2000"
        class="col-md-6 col-12 d-flex justify-content-center align-items-center flex-column p-0 m-0">
        <div class="sub-title text-center mt-md-4 mb-4">
          <img class="cfp-logo" src="@/assets/img/cfp-logo.svg" alt="" /> <br />
          <span class="title">Creative Footprint</span> <br />
          <span class="title-bold title">Rotterdam</span>
        </div>

        <div class="report-title-e d-flex justify-content-center align-items-center text-center">
          <span class="title-bold">Report 2024</span>
          <br />

          <div class="title-description">
            Creative Footprint Rotterdam was conducted by VibeLab in 2024, commissioned by N8W8 R’dam and
            supported by the
            Municipality of Rotterdam.
          </div>
        </div>
      </div>
    </div>

    <div class="logos">
      <div data-aos="fade" data-aos-offset="-300" class="container-fluid d-flex justify-content-center">
        <svg @click="goDown" style="transform: rotate(180deg)" width="48" height="48" viewBox="0 0 48 48" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="24" cy="24" r="23.5" fill="#e8fcd4" stroke="black"></circle>
          <path d="M18 26L24.5 20L31 26" stroke="black" stroke-width="1.5" stroke-linecap="round"></path>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingPage",
  data() {
    return {};
  },
  methods: {
    circleStyle(el) {
      const op = (100 * el) / 20;
      const opacity = (60 * el) / 20;
      const size = op / 60 + 0.915;

      return `transform:scale(${size}); opacity:${opacity}%;animation-delay: ${op * 6
        }ms;`;
    },
    goDown() {
      let y =
        document.getElementById(`summary`).getBoundingClientRect().top +
        window.scrollY;
      window.scroll({
        top: y,
        behavior: "smooth",
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cfp-logo {
  width: 4rem;
  height: 4rem;
}

.vibelab-logo {
  height: 1.5rem;
}

.circle-bg {
  background: url("~@/assets/img/front_cover.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: cover;
  position: absolute;
  right: -50%;
  left: inherit;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 100vw;
  width: 100vw;
  border-radius: 50%;
  z-index: 0;
}

.circle-shape {
  position: absolute;
  right: -50%;
  top: 0;
  left: inherit;
  bottom: 0;
  margin: auto;
  height: 100vw;
  width: 100vw;
  border-radius: 50%;
  z-index: 0;
  animation: flash 3s infinite linear;
}

@media (min-width: 768px) {
  .circle-shape {
    position: absolute;
    right: -30%;
    top: 0;
    left: inherit;
    bottom: 0;
    margin: auto;
    height: 80vw;
    width: 80vw;
    border-radius: 50%;
    z-index: 0;
    animation: flash 3s infinite linear;
  }

  .circle-bg {
    position: absolute;
    right: -30%;
    top: 0;
    left: inherit;
    bottom: 0;
    margin: auto;
    height: 80vw;
    width: 80vw;
    border-radius: 50%;
    z-index: 0;
  }
}

@keyframes flash {
  0% {
    border: solid 1px transparent;
  }

  12% {
    border: solid 1px transparent;
  }

  15% {
    border: solid 1px rgb(114, 127, 75);
  }

  30% {
    border: solid 1px rgba(114, 127, 75, 0.116);
  }

  48% {
    border: solid 1px rgba(114, 127, 75, 0);
  }

  75% {
    border: solid 1px transparent;
  }

  100% {
    border: solid 1px transparent;
  }
}

.content-wrapper {
  z-index: 1;
  color: #e8fcd4;
}

img.portrait {
  width: 16rem;
  border-radius: 2rem;
}

.presents {
  position: absolute;
  top: 5rem;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}

.logos {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5rem;
  z-index: 1;
}

svg:hover {
  cursor: pointer;
}

.vl {
  width: 7rem;
}

.xp {
  width: 6rem;
}

.report-title-a {
  line-height: 2rem;
  font-size: 2.2rem;
}

.report-title-e {
  line-height: 2rem;
  font-size: 1.5rem;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.title-description {
  width:100%;
}

@media (min-width: 768px) {
.title-description {
    width: 70%;
  }
}



.title-bold {
  font-family: SkModernistBold, Helvetica, Arial, sans-serif;
}

.sub-title {
  z-index: 1;
  font-size: 3.5rem;
  line-height: 3.5rem;
}

.landing {
  top: 0;
  bottom: 0;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  // min-height: -webkit-fill-available;
}
</style>
